@import "../../../styles/common.css";

.home {
  background-color: var(--light-orange);
  background-image: linear-gradient(to right, #f8e4c7 0.5px, transparent 1px),
    linear-gradient(to bottom, #f8e4c7 0.5px, transparent 1px);
  background-size: 30px 30px;
  width: 100%;
}

.home1 {
  min-height: 100vh;
  background-color: var(--light-orange);
  background-image: linear-gradient(to right, #f8e4c7 0.5px, transparent 1px),
    linear-gradient(to bottom, #f8e4c7 0.5px, transparent 1px);
  background-size: 30px 30px;
  max-width: 100%;
  padding-bottom: 20px;
}

.home1Top {
  height: 100%;
}

.home1Left {
  width: 52%;
  height: 100%;
  float: left;
}

.home1Right {
  width: 45%;
  height: 100%;
  float: right;
}

.home1LeftBox {
  background-color: var(--light-purple);
  border-radius: 20px;
  /* border: 2px solid var(--purple); */
  border: 2px solid #111;
  box-shadow: 4px 3px 0 #111;
  margin: 10px;
  margin-top: calc(28% + 7vh);
  margin-left: 13%;
  margin-right: 3%;
}

.h1LHeader {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* border-bottom: 2px solid var(--purple); */
  border-bottom: 2px solid #111;
  width: calc(100% - 36px);
  background-color: var(--purple);
  padding: 15px 18px;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.h1LHP {
  margin-top: 2px;
  margin-bottom: 4px;
}

.h1P {
  margin-left: 6px;
  font-size: 18px;
  font-weight: 500;
}

.h1LSH {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
}

.h1Buttons {
  display: flex;
  /* justify-content: space-around; */
  margin-top: 10px;
}

.h1Button {
  border: 2px solid #111;
  box-shadow: 2px 1.5px 0px #111;
  color: #111;
  padding: 3px 8px;
  margin-right: 14px;
  margin-bottom: 15px;
  border-radius: 10px;
  min-width: 100px;
  grid-column-start: 1;
  width: 90%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

.h1ButtonHover {
  background-color: var(--dark-orange) !important;
  color: white !important;
  transition: all 0.2s;
}

.h1ButtonText {
  margin: 5px 8px 0 2px;
  float: right;
}

.h1LinkedinIcon {
  margin-top: 6px !important;
  margin-right: 4px !important;
  padding-bottom: 2px;
  height: 20px !important;
}

.h1Icon {
  margin: 6px 6px 0 4px;
  height: 22px;
}

.home1TopCircles {
  display: flex;
}

.home1BottomCircles {
  display: flex;
}

.h1RC {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* background-color: red; */
  border: 2px solid #111;
  box-shadow: 1px 1px 0px #111;
  position: absolute;
}

.h1RL {
  color: var(--orange);
  /* color: var(--purple); */
  font-weight: 600;
}

.h1RL:hover {
  color: var(--purple);
  /* color: var(--orange); */
  transition: 0.2s all;
}

.h1BT {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: calc(100% - 40px);
  padding: 16px 20px 20px 18px;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
  /* color: var(--purple); */
  color: #111;
}

.h1BP {
  margin-top: 6px;
  margin-bottom: 18px;
}

.home1BottomBoxButtons {
  margin-top: 10px;
  display: grid;
}

.h1Button:nth-of-type(1) {
  grid-column-start: 2;
}

.home1Portrait {
  width: 70%;
  height: auto;
  border-radius: 50%;
  margin-top: calc(60px + 15vh);
  margin-left: calc(40px + 2%);
  margin-right: 2%;
  box-shadow: 6px 5px 0px #111;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.home2 {
  min-height: calc(100vh - 40px);
  padding-top: 50px;
  padding-bottom: 10px;
  background-color: var(--light-orange);
  background-image: linear-gradient(to right, #f8e4c7 0.5px, transparent 1px),
    linear-gradient(to bottom, #f8e4c7 0.5px, transparent 1px);
  background-size: 30px 30px;
  max-width: 100%;
  overflow: hidden;
}

.home2SplitHeaders {
  margin: 0 5%;
  display: flex;
  justify-content: space-around;
}

.home2SplitLeft,
.home2SplitRight {
  width: 50%;
}

.home2ExperienceHeader {
  width: 36%;
  margin-bottom: 20px;
  padding: 10px 5px;
  background-color: var(--light-purple);
  /* background-color: black; */
  border-radius: 20px;
  border: 2px solid #111;
  box-shadow: 4px 3px 0 #111;
  text-align: center;
}

.home2DownloadHeader {
  float: right;
  width: 18%;
  margin-top: 14px;
  margin-bottom: 20px;
  padding: 5px 8px;
  background-color: var(--light-purple);
  /* background-color: black; */
  border-radius: 10px;
  border: 2px solid #111;
  box-shadow: 4px 3px 0 #111;
  color: #111;
  text-decoration: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

.home2DownloadIcon {
  margin: 4px 0 0 6px;
  height: 24px;
}

.home2DownloadHeaderText {
  float: right;
  font-size: 20px;
  font-weight: 500;
  line-height: 145%;
  margin: 3px 15% 0 0;
}

.home2ProjectsHeaderText {
  font-size: 24px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 4px;
}

.home2ProjectsHeader {
  width: 16%;
  margin-left: 5%;
  margin-bottom: 20px;
  padding: 10px 5px;
  background-color: var(--light-purple);
  /* background-color: black; */
  border-radius: 20px;
  border: 2px solid #111;
  box-shadow: 4px 3px 0 #111;
  text-align: center;
}

.home2Projects {
  margin-bottom: 120px;
  overflow: hidden;
}

.home2Projects:last-of-type {
  padding-bottom: 40px;
}

@media screen and (max-width: 1650px) {
  .home2DownloadHeader {
    width: 22%;
  }

  .home2DownloadHeaderText {
    margin: 3px 16% 0 0;
  }
}

@media screen and (max-width: 1400px) {
  .home1Left {
    margin-top: 18%;
    margin-left: 0;
  }

  .home1Right {
    margin-top: 20%;
  }

  .home1LeftBox {
    margin-top: calc(10px + 12vh);
    margin-left: 10%;
    margin-right: 4%;
  }

  .h1LHeader {
    font-size: 20px;
  }

  .h1Button {
    padding: 2px 7px;
    line-height: 26px;
  }

  .h1Icon {
    margin: 6px 5px -1px 5px;
    height: 22px;
  }

  .h1LSH,
  .h1P,
  .h1BP,
  .h1ButtonText {
    font-size: 16px;
  }

  .home1Portrait {
    width: 80%;
    margin-top: calc(20px + 5vh);
    margin-left: calc(10px + 2%);
  }

  .home2DownloadHeader {
    width: 25%;
  }

  .home2DownloadHeaderText {
    margin: 4px 15% 0 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .home1LeftBox {
    margin-top: 25%;
  }

  .home1Portrait {
    margin-top: 25%;
  }
}

@media screen and (max-width: 1100px) {
  .home1Portrait {
    margin-left: 20%;
    width: 60%;
    height: auto;
    margin-top: 12%;
  }

  .home1Top {
    padding-bottom: 55px;
  }

  .home1BottomBox {
    background-color: var(--light-purple);
    border-radius: 20px;
    /* border: 2px solid var(--purple); */
    border: 2px solid #111;
    /* box-shadow: 4px 3px 0 var(--purple); */
    box-shadow: 4px 4px 0 #111;
    margin: 25px 6% 0 6.25%;
  }

  .home1BottomBoxHeader {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 2px solid #111;
    padding: 15px 18px;
    width: calc(100% - 36px);
    background-color: var(--purple);
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .home1BottomBoxHeaderBig {
    margin-top: 2px;
    margin-bottom: 4px;
  }

  .home1BottomBoxHeaderSmall {
    margin-left: 8px;
    font-size: 18px;
    font-weight: 300;
  }

  .home1BottomBoxSubhead {
    margin-top: 6px;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 300;
  }

  .home1BottomBoxBody {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: calc(100% - 40px);
    padding: 10px 20px 10px 18px;
    font-weight: 400;
    color: #111;
  }

  .home1BottomBoxBodyP {
    margin-top: 6px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 135%;
  }

  .home2ExperienceHeader {
    width: 48%;
  }

  .home2DownloadHeader {
    width: 32%;
  }

  .home2DownloadHeaderText {
    margin: 4px 15% 0 0;
  }

  .home2ProjectsHeader {
    width: 21.5%;
  }
}

@media screen and (max-width: 850px) {
  .home1 {
    padding-bottom: 10px;
  }

  .home1Portrait {
    margin-top: 10%;
  }

  .home2Projects {
    margin-bottom: 80px;
  }

  .home2Projects:last-of-type {
    padding-bottom: 30px;
  }

  .home2ExperienceHeader {
    width: 72%;
    margin: 0 0 20px 2%;
  }

  .home2DownloadHeader {
    width: calc(100px + 18%);
    margin: 11px 2% 20px 0;
  }

  .home2DownloadHeaderText {
    float: right;
    font-size: 20px;
    font-weight: 500;
    line-height: 145%;
    margin: 3px calc(20px + 5%) 0 0;
  }

  .home2ProjectsHeader {
    width: 32%;
    margin: 0 6% 20px 6%;
  }
}

@media screen and (max-width: 500px) {
  .home1Top {
    padding-bottom: 10px;
  }

  .home1BottomBoxBody {
    width: calc(100% - 40px);
    padding: 10px 14px 10px 18px;
  }

  .home1BottomBoxHeader {
    padding: 15px 18px;
    font-size: 20px;
  }

  .home1BottomBoxHeaderSmall {
    font-size: 16px;
  }

  .home1BottomBoxSubhead {
    margin-top: 2px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 300;
  }

  .home1BottomBoxButtons {
    display: flex;
    flex-direction: column;
  }

  .h1Button {
    padding-left: 1px;
    width: 95%;
  }

  .home2ExperienceHeaderText,
  .home2ProjectsHeaderText {
    font-size: 20px;
  }

  .home2DownloadIcon {
    margin: 6px 0 0 4px;
    height: 18px;
  }

  .home2DownloadHeaderText {
    font-size: 18px;
  }

  .home2Projects:last-of-type {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 350px) {
  .home2ExperienceHeader {
    width: 80%;
  }

  .home2DownloadHeader {
    width: 65%;
  }

  .home2DownloadIcon {
    margin-left: 2px;
  }

  .home2DownloadHeaderText {
    font-size: 16px;
    margin: 4px calc(5% - 2px) 0 0;
  }

  .home2ProjectsHeader {
    width: 36%;
  }
}
