body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span,
div {
  padding: 0;
  margin: 0;
}

/* .container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
  background-color: white;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
} */
