@import "../../../styles/common.css";

.projectCard {
  /* width: calc(50% - 60px); */
  background-color: var(--light-purple);
  border-radius: 20px;
  border: 2px solid #111;
  box-shadow: 4px 3px 0 #111;
  margin: 30px 5% 30px 5%;
}

.projectCardHeader {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 2px solid #111;
  width: calc(100% - 36px);
  padding: 15px 18px;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.projectCardText {
  margin-top: 2px;
  margin-bottom: 6px;
}

.projectCardSubhead {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
}

.projectCardBody {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 16px 18px 18px 18px;
  font-size: 18px;
  font-weight: 400;
  line-height: 145%;
  color: #111;
}

@media screen and (max-width: 850px) {
  .projectCard {
    margin: 25px 6%;
  }

  .projectCardBody {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .projectCardHeader {
    font-size: 20px;
  }

  .projectCardSubhead {
    font-size: 16px;
  }
}
