/**

Environment styles

**/

/* Figtree Font */
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

/* Secondary and Accent Colors */
:root {
  --purple: #7529fa;
  --light-purple: #faf5ff;
  --orange: #fc5d03;
  --dark-orange: #eb5600;
  --light-orange: #ffecd1;
  --light-pink: #fff2fb;
  --light-gray: #9b9b9b;
  -webkit-overflow-scrolling: touch;
}

* {
  font-family: Figtree, sans-serif;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #555;
}

::-webkit-scrollbar-button {
  height: 0px;
}

::selection {
  background: #222; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: #222; /* Gecko Browsers */
  color: #fff;
}

.common-button {
  border-radius: 4px;
  font-size: 18px;
  color: white;
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 50%); */
  font-weight: 500;
  line-height: 36px;
  text-decoration: none;
  padding: 0px 30px;
  margin-top: 30px;
  width: 100px;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .common-pink-button {
    font-size: 14px;
  }
}
